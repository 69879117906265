/* .alice-carousel__stage li .thumb .item img {
  width: 100px;
  height: 100px;
  object-fit: scale-down;
  padding: 10px;
  border: 1px solid var(--light);
  cursor: pointer;
  transition: all 0.3s ease;
}

.alice-carousel__stage li {
  object-fit: cover;
  width: 768px;
  height: 500px;
}
.thumbs .alice-carousel__stage-item__active__target,
.thumbs .alice-carousel__stage-item,
.thumbs .alice-carousel__stage-item__cloned {
  width: 100px;
  height: 120px;
  padding: 10px;
  object-fit: cover;
}
.thumbs .alice-carousel__stage-item__active__target .thumb .item img {
  border: 4px solid var(--primary) !important;
}
.thumbs .alice-carousel__stage-item.__target .thumb .item img {
  border: 4px solid var(--primary) !important;
}

.alice-carousel-btn-prev .fa-angle-left,
.alice-carousel-btn-next .fa-angle-right {
  color: #000 !important;
  background: var(--white);
} */

.css-1v5i58q-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;

  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 4px;
  border: 1px solid white !important;
  border-color: white !important;
  box-shadow: none !important;
  box-shadow: 0 !important;
  outline: 0 !important;
}

.AbstractModalHeader {
  position: relative;
}

.make-offer-modal .btn-close {
  position: absolute;
  border: none !important;
  background-color: white !important;
  top: 28px;
  right: 31px;
  font-size: 1.5rem;
  color: #000;
  opacity: 0.5;
  cursor: pointer;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.make-offer-modal .btn-close::after {
  content: "\00d7"; /* Unicode for times symbol */
  font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 var(--white);
}

.btn-close:hover::after {
  color: #000; /* Change color on hover as needed */
}
.css-3wb1p2-MuiTypography-root {
  height: 39px !important;
}

.css-1dv8eyl-MuiButtonBase-root-MuiMenuItem-root {
  height: 39px !important;
}

/* 
.MuiTypography-root::after {
    top: 9px;
    
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M10 17l5-5-5-5v10z"></path></svg>');
   
    
    display: inline-block;
    margin-left: 5px;
    width: 28px;
    height: 19px;
    /* content: url(data:image/svg+xml;utf8,<svg focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M10 17l5-5-5-5v10z"></path></svg>); */
/* position: relative;
    left: -5px;
} */

.css-11hpejz-MuiFormLabel-root-MuiInputLabel-root {
  top: -2px !important;
}
.MuiButtonBase-root .MuiBox-root::after {


    display: inline-block;
    margin-left: -7px;
    width: 23px;
    height: 30px;
    margin-top: 9px;
    
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M10 17l5-5-5-5v10z"></path></svg>');
}

.MuiList-root .MuiButtonBase-root .MuiTypography-root {
  line-height: 40.5px !important;
  height: 34px !important;
}
.MuiList-root .MuiButtonBase-root .MuiSvgIcon-root {
  display: none !important;
}
.MuiMenuItem-root {
  height: 34px !important;
}
.sectionDesktop .right .MuiButtonBase-root:nth-child(1){
  padding: 9px !important;
  /* background-color: green !important; */
}
.sectionDesktop .right .MuiButtonBase-root:nth-child(3){
  padding: 12px !important;
  /* background: red; */
}
.MuiMenuItem-root:hover{

  height: 34px !important;
}
/* .css-1dv8eyl-MuiButtonBase-root-MuiMenuItem-root {
  .css-i4bv87-MuiSvgIcon-root{
    display:none !important;
  }
} */

.css-1fia7xg-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: white !important;
}
.css-1fia7xg-MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.image-gallery {
  height: 100% !important;
}
.sectionDesktop .MuiButtonBase-root:nth-child(1):hover {
  border: none !important;
}


